.game{
    position: absolute;
    height: 50px;
    width: 50px;
    border-radius: 10px;
    background-color: red;

}

@media only screen and (max-width: 500px) {
    .game{
        height: 25px;
        width: 25px;
        border-radius: 5px;
    }
  }