.app-section,.landing{
  overflow-x: hidden;
}


html,body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    scroll-behavior: smooth;
    overflow-x: hidden; 
}

@import url('https://fonts.googleapis.com/css2?family=Exo:wght@100;300;900&family=Heebo:wght@200;900&family=Secular+One&display=swap');

.row{
  width: 80% !important;
  justify-content: center !important;
  flex-wrap: wrap !important;
  margin: auto !important;
}

.App {
  text-align: center;
}

.app-section{
  margin: 0;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.app-section h1{
  margin: 0;
  padding-top: 0;
  padding-bottom: 2rem;
  font-size: 2rem;
  font-weight: 600;
 
}

.cursor{

cursor: pointer;

}

.exo-font{
  font-family: 'Heebo', sans-serif;
  /* font-family: 'Exo', sans-serif; */
}

.secular-font{
  font-family: 'Secular One', sans-serif;
  font-weight: 700;
  font-size: 4rem;
}

* h1, h2, h3, h4, h5, h6{
 
  margin: 0;
  padding: 0;
  font-size: 2rem;
  font-weight: 600;
}

.font-weight{
  font-size: 2rem;
  font-weight: 600;
}

* {
  scrollbar-width: thin;
  scrollbar-color: transparent;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  display: none;
}

*::-webkit-scrollbar-thumb {
  background: rgba(90, 90, 90);
  border-radius: 30px;
}

body{
  overflow: overlay;
}


@media only screen and (min-width: 2500px) {
  .row{
    width: 65% !important;
   
  }
}