.tech-display{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    justify-content: center;
   
}
.tech-display div{
    text-align: left;
}

.tech-display div p{
    display: inline-block;
    margin: 1rem;
}

.tech{

    display: flex;
    align-content: center;
    justify-items: center;
    justify-content: center;

}

.tech-header{
    width: 80%;
}


.underline{
    text-decoration: underline;
}





@media only screen and (min-width: 1500px) {
    .tech-display {
      font-size: 1.5rem;
    }

    .tech-display div{
        width: 200px !important;
      }
  }

  @media only screen and (max-width: 454px) {
    .tech-option{
        width: 132px !important;
    }
    .tech-option p{
        font-size: 13px !important;
    }
  }

  @media only screen and (max-width: 409px) {
    .tech-option{
        width: 120px !important;
        margin: .5em !important;
    }
    .tech-option p{
        font-size: 13px !important;
    }
  }