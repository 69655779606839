.contact {
    box-shadow: 0px -8px 20px #383838;
        background-color: #CA4246;
      
        /* Create the gradient. */
         background-image: linear-gradient(
              135deg,
              #f1f1f1 50%,
              #0f3d3e 50%
              
              );
              
        
        /* Set the background size and repeat properties. */
        background-size: 100%;
        background-repeat: repeat;
      
      
    
}

/* #CA4246 16.666%, 
              #E16541 16.666%, 
              #E16541 33.333%, 
              #F18F43 33.333%, 
              #F18F43 50%, 
              #8B9862 50%, 
              #8B9862 66.666%, 
              #476098 66.666%, 
              #476098 83.333%, 
              #A7489B 83.333%); */