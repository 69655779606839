.about-section{
    background-color: #F1F1F1;
    width: 100% !important;
}

.donut-graph {
   max-width: 500px !important;
}


.cert-section .col{
    justify-content: center !important;
    display: flex !important;
    margin-top: 15px;
}

@media only screen and (min-width: 1500px) {
    .about-paragraph {
      font-size: 1.5rem !important;
    }

    
  }